<template>
  <div style="width: 70%;background-color: #FFF5DB;border-radius:35px;margin-top:-0px">
    <div class="login-form login-signin" style="padding: 27px;">
      <div class="mb-2 mb-lg-2">
        <h1 class="font-size-h1" style="color: #0F0090">
         Welcome back
      </h1>
      <div style="color: #827AC5;font-size: 17.7px;">
        Login in to your account
      </div>
      </div>

      <b-form class="form mt-10" @submit.stop.prevent="onSubmit">
        
        <div >  Email</div>
        <div class="inp">
          <input 
            placeholder="Email"
            class=""
            autocomplete="on"
            v-model="$v.form.email.$model"/>

            <hr style="margin:0; border: 1px solid #908aca9e;">
          <!-- <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback> -->
      </div>

        <div class="mt-4">  Password</div>
        <div class="inp">
          <input
            placeholder="Password"
            class=""
            type="password"
            autocomplete="on"
            v-model="$v.form.password.$model"
          />
            <hr style="margin:0; border: 1px solid #908aca9e;">
      </div>
          <!-- <b-form-invalid-feedback id="input-2-live-feedback">
            Password is required.
          </b-form-invalid-feedback> -->
        <!-- <b-form-group
          id="example-input-group-1"
          label="Email"
          label-for="example-input-1"
        >
          <b-form-input
            placeholder="Email"
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-1"
            autocomplete="on"
            name="example-input-1"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </b-form-group> -->

        <!-- <b-form-group
          id="example-input-group-2"
          label="Password"
          label-for="example-input-2"
        >
          <b-form-input
            placeholder="Password"
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            autocomplete="on"
            name="example-input-2"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Password is required.
          </b-form-invalid-feedback>
        </b-form-group> -->
        <div>
        </div>

          <div class="forgot">New here?
            <!-- @click="$router.push('register')" -->
            <span style="font-weight: bold;cursor: pointer;"> Create an account</span></div>
        <div class="form-group">
          <button
            style="font-size: 16px;border-radius: 35px;"
            ref="kt_login_signin_submit"
            class="btn btn-dark form-control"
            :disabled="!form.email || !form.password"
          >
            Log in
          </button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      errorMessage: "",
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async onSubmit() {
      let submitButton;
      try {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }

        const email = this.$v.form.email.$model;
        const password = this.$v.form.password.$model;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        submitButton = this.$refs["kt_login_signin_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        await this.$store.dispatch(LOGIN, {
          email,
          password,
          type:'dados'
        });

        // go to which page after successfully login
        localStorage.setItem("lastSeen", new Date());
        localStorage.setItem('is_login',1);
         localStorage.setItem(
          "session-start",
          `${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}`
        );
        this.$router.push({
          name: "dashboard-home",
        });
      } catch (e) {
        this.errorMessage = e.message;
        this.$bvToast.toast(this.errorMessage, {
          title: "Login Info",
          autoHideDelay: 3000,
          appendToast: false,
          solid: true,
          variant: "danger",
          fade: true,
        });
      } finally {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }
    },
  },
};
</script>
<style scoped>

.inp {
  position: relative;
  width: 100%;
  /* max-width: 280px; */
  height: 53px;
}
.inp input {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 0;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  background: none;
  border-radius: 0;
  color: #223254;
  transition: all 0.15s ease;
}

.inp input:focus {
  outline: none;
}


::placeholder {
  color: #9098A9;
}

/* .form-control.form-control-solid {
  background-color: #fff2f2;
  border-color: #f3fcfe;
  border-radius: 5px;
  font-size: 16px;
}
::placeholder {
  color: #37a4cb !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #37a4cb !important;
}

::-ms-input-placeholder {
  color: #37a4cb !important;
} */

.sign-up-link {
  color: #000;
  font-weight: bold !important;
}
.head {
  color: black;
  font-weight: 700;
}
.forgot {
  margin-top: 25px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color:#0F0090;
}

.btn {
  margin-top: 30px;
  border-radius: 5px;
}
.btn-dark {
  height: 52px;
  background: #0F0090;
  border-color: #0F0090;
}
.btn-dark:hover {
  background: #0F0090 !important;
  border-color: #0F0090 !important;
}
</style>